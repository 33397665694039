<template>
  <span>
    {{ item?.client?.name ? $t('driving.client') + ': ' : '' }}
    <b v-if="item?.client?.name">
      {{ item?.client?.name }}
    </b>

    <br v-if="item?.client?.name" />

    {{ getDriversName(item?.drivingProposal?.user_id) ? $t('driving.driver') + ': ' : '' }}
    <b v-if="item?.drivingProposal?.user_id">
      {{ getDriversName(item?.drivingProposal.user_id) }}
    </b>

    <br v-if="item?.drivingProposal?.user_id" />

    {{ JSON.parse(item?.from_location)?.name ? $t('driving.locationFrom') + ': ' : '' }}
    <b v-if="JSON.parse(item?.from_location)?.name">
      {{ JSON.parse(item?.from_location)?.name }}
    </b>

    <br v-if="JSON.parse(item?.to_location)?.name" />

    {{ JSON.parse(item?.to_location)?.name ? $t('driving.locationTo') + ': ' : '' }}
    <b v-if="JSON.parse(item?.to_location)?.name">
      {{ JSON.parse(item?.to_location)?.name }}
    </b>

    <br />

    {{ item?.pickup_time ? $t('driving.pickupTime') + ': ' : '' }}
    <b v-if="item?.pickup_time">
      {{ item?.pickup_time }}
    </b>
  </span>
</template>

<script>
export default {
  name: 'DrivingInfoTooltip',
  props: ['item', 'drivers'],
  methods: {
    getDriversName(id) {
      if (id) {
        let index = this.drivers.findIndex((x) => x.id == id);
        return this.drivers[index]?.full_name;
      } else {
        return '';
      }
    },
  },
};
</script>
