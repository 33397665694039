var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"auto","md":"12","sm":"12"}},[_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('span',{staticClass:"d-block font-weight-bold"},[_vm._v(_vm._s(_vm.$t('dashboardPage.drivingCountToday')))]),_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(_vm.mainBannerData?.drivingCountToday))])]),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('span',{staticClass:"d-block font-weight-bold"},[_vm._v(_vm._s(_vm.$t('dashboardPage.drivingCountThisMonth')))]),_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(_vm.mainBannerData?.drivingCountThisMonth))])]),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('span',{staticClass:"d-block font-weight-bold"},[_vm._v(_vm._s(_vm.$t('dashboardPage.dailyRentsAndTransfers')))]),_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm.mainBannerData?.drivingTypeDailyRentCount + '/' + _vm.mainBannerData?.drivingTypeTransfersCount)+" ")])]),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('span',{staticClass:"d-block font-weight-bold"},[_vm._v(_vm._s(_vm.$t('dashboardPage.drivingTotalAmount')))]),_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(_vm.mainBannerData?.drivingTotalAmount))])]),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('span',{staticClass:"d-block font-weight-bold"},[_vm._v(_vm._s(_vm.$t('dashboardPage.driversCount')))]),_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(_vm.mainBannerData?.driversCount))])]),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('span',{staticClass:"d-block font-weight-bold"},[_vm._v(_vm._s(_vm.$t('dashboardPage.passengerCount')))]),_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(_vm.mainBannerData?.passengerCount))])])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4","xl":"6"}},[_c('v-data-table',{staticClass:"elevation-1 cursor-pointer",attrs:{"footer-props":{
          pageText: `{0}-{1} ${_vm.$t('tables.of')} {2}`,
          itemsPerPageText: _vm.$t('tables.drivingsByPage'),
          itemsPerPageOptions: [5, 10, 20, 50, 100],
        },"headers":_vm.drivingInNext3DaysHeaders,"no-data-text":_vm.$t('tables.noDataText'),"no-results-text":_vm.$t('tables.noDataText'),"items":_vm.drivingInNext3Days,"items-per-page":10,"multi-sort":""},on:{"click:row":function($event){return _vm.showEvent({ nativeEvent: false, event: _vm.item })}},scopedSlots:_vm._u([{key:"item.price",fn:function({ item }){return [_vm._v(" "+_vm._s(item.currency.symbol + item.price)+" ")]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t('tables.inNext3Days'))+" ")])]],2)]},proxy:true},(_vm.$vuetify.breakpoint.smAndUp)?{key:"item",fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('tr',_vm._g(_vm._b({on:{"click":function($event){return _vm.showEvent({ nativeEvent: false, event: item })}}},'tr',attrs,false),on),[_c('td',[_vm._v(_vm._s(item.number))]),_c('td',[_vm._v(_vm._s(item.currency.symbol + item.price))]),_c('td',[_vm._v(_vm._s(item.from_location_name))]),_c('td',[_vm._v(_vm._s(item.vehicle?.name))])])]}}],null,true)},[_c('driving-info-tooltip',{attrs:{"item":item,"drivers":_vm.drivers}})],1)]}}:null],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('v-data-table',{staticClass:"elevation-1 cursor-pointer",attrs:{"footer-props":{
          pageText: `{0}-{1} ${_vm.$t('tables.of')} {2}`,
          itemsPerPageText: _vm.$t('tables.drivingsByPage'),
          itemsPerPageOptions: [5, 10, 20, 50, 100],
        },"headers":_vm.drivingNotPaidOrConfirmedHeaders,"no-data-text":_vm.$t('tables.noDataText'),"no-results-text":_vm.$t('tables.noDataText'),"items":_vm.drivingNotPaidOrConfirmed,"items-per-page":10,"multi-sort":""},on:{"click:row":function($event){return _vm.openDrivshowEvent({ nativeEvent: false, event: _vm.item })}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t('tables.notConfirmedOrPaid'))+" ")])]],2)]},proxy:true},(_vm.$vuetify.breakpoint.smAndUp)?{key:"item",fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('tr',_vm._g(_vm._b({on:{"click":function($event){return _vm.showEvent({ nativeEvent: false, event: item })}}},'tr',attrs,false),on),[_c('td',[_vm._v(_vm._s(item.number))]),_c('td',[_c('v-icon',[_vm._v(_vm._s(parseInt(item.paid) ? 'mdi-check' : 'mdi-close'))])],1),_c('td',[_c('v-icon',[_vm._v(_vm._s(parseInt(item.client_confirmed) ? 'mdi-check' : 'mdi-close'))])],1)])]}}],null,true)},[_c('driving-info-tooltip',{attrs:{"item":item,"drivers":_vm.drivers}})],1)]}}:null],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"12","lg":"4","xl":"3"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"12"}},[_c('v-data-table',{staticClass:"elevation-1 cursor-pointer",attrs:{"footer-props":{
              pageText: `{0}-{1} ${_vm.$t('tables.of')} {2}`,
              itemsPerPageText: _vm.$t('tables.driversByPage'),
              itemsPerPageOptions: [5, 10, 20, 50, 100],
            },"headers":_vm.driversInNext5DaysHeaders,"no-data-text":_vm.$t('tables.noDataText'),"no-results-text":_vm.$t('tables.noDataText'),"items":_vm.driversInNext5Days,"items-per-page":5,"multi-sort":""},on:{"click:row":_vm.openDrivingWithDriver},scopedSlots:_vm._u([{key:"item.full_name",fn:function({ item }){return [_vm._v(" "+_vm._s(item.first_name + ' ' + item.last_name)+" ")]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t('tables.inNext5Days'))+" ")])]],2)]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"footer-props":{
              pageText: `{0}-{1} ${_vm.$t('tables.of')} {2}`,
              itemsPerPageText: _vm.$t('tables.reviewsByPage'),
              itemsPerPageOptions: [5, 10, 20, 50, 100],
            },"headers":_vm.passengerReviewsLast5Headers,"no-data-text":_vm.$t('tables.noDataText'),"no-results-text":_vm.$t('tables.noDataText'),"items":_vm.passengerReviewsLast5,"items-per-page":5,"multi-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t('tables.latestReviews'))+" ")])]],2)]},proxy:true}])})],1)],1)],1)],1),_c('driving-modal',{attrs:{"selectedElement":_vm.selectedElement}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }